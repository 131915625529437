@import "media"
  
.modal-backdrop
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(28, 24, 20, .5)
  backdrop-filter: blur(5px)
  display: flex
  justify-content: center
  align-items: center
  z-index: 999
  visibility: hidden
  transition: transform 0.3s ease
  &.show
    visibility: visible
    .modal-content
      transform: translateY(0)
      opacity: 1
  

.modal-content
  width: 90%
  max-width: 600px
  background-color: white
  border-radius: 4px
  box-shadow: 0 8px 24px -6px rgba(0, 0, 0, 0.16)
  position: relative
  z-index: 1000
  transform: translateY(50px)
  opacity: 0
  transition: transform 0.3s ease, opacity .3s ease

.close-button
  border: none
  background: none
  font-size: 28px
  cursor: pointer
  color: rgba(28, 24, 20, .5)
  &:hover
    color: rgba(28, 24, 20, 1)

.modal-header
  padding: 8px 40px
  padding-right: 20px
  width: 100%
  display: flex
  justify-content: space-between
  align-items: center
  border-bottom: 1px solid rgba(28, 24, 20, .1)
  @include media("mobile")
    padding: 8px 32px
    padding-right: 0


.modal-title
  font-size: 14px
  font-weight: 600
  line-height: 100%

.page-preloader
  width: 100vw
  height: 100vh
  display: flex
  align-items: center
  justify-content: center