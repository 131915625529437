@import "media"

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap')

$site-width: 1340px
$brown-bg: #1C1814

*
    font-family: 'Montserrat', sans-serif

.content-wrapper
    width: 100%
    max-width: $site-width
    margin: 0 auto
    padding: 0 20px

.link-btn
    text-decoration: none
    font-size: 14px
    font-weight: 600
    color: $brown-bg
    border: 1px solid transparentize($brown-bg, .5)
    padding: 6px 12px
    border-radius: 30px

.arr-btn
    text-decoration: none
    font-size: 16px
    font-weight: 600
    color: white
    padding: 6px 0
    border-radius: 30px
    display: flex
    align-items: center
    &::after
        content: ""
        display: block
        margin-left: 10px
        width: 16px
        height: 16px
        background-image: url("../../public/img/arrow-right-white.svg")
        background-size: cover
        background-position: center
        background-repeat: no-repeat

.main-btn
    padding: 8px 22px
    background-color: $brown-bg
    color: white
    font-size: 16px
    font-weight: 600
    border: none
    text-decoration: none
    display: flex
    align-items: center
    justify-content: center
    border-radius: 6px
    transition: background-color .3s ease
    cursor: pointer
    &:hover
        background-color: lighten($brown-bg, 10)
    &:active
        background-color: lighten($brown-bg, 20)
    &:disabled
        background-color: $brown-bg
        cursor: default
        opacity: .5
    &.loading
        background-color: $brown-bg
        cursor: progress
        opacity: .5
        

.site-header-wrapper
    width: 100%
    position: absolute
    z-index: 999
    top: 0
    left: 0
    .site-header
        padding: 14px 20px
        display: flex
        align-items: center
        justify-content: space-between
        .header-logo
            height: 74px
    .header-contact
        @include media("tablet")
            display: none

.header-contact
    font-weight: 600
    text-decoration: none
    border: 1px solid white
    border-radius: 100px
    color: white
    font-weight: 600
    font-size: 14px
    line-height: 14px
    padding: 10px 20px
    .platform
        margin-right: 7px

.header-nav
    display: flex
    align-items: center
    justify-content: flex-end
    flex-grow: 1
    margin-right: 50px
    .nav-link
        color: white
        text-decoration: none
        margin-right: 30px
        font-weight: 600
        font-size: 14px
        padding: 4px 6px
        opacity: .6
        &:last-of-type
            margin-right: 0px
        &:hover
            opacity: .8
        &.active
            opacity: 1
    @include media("tablet")
        display: none

.mobile-menu-wrapper
    position: fixed
    z-index: 9999
    left: 0
    right: 0
    top: 0
    bottom: 0
    background: $brown-bg
    align-items: center
    display: none
    visibility: hidden
    justify-content: center
    @include  media("tablet")
        visibility: visible
    &.opened
        display: flex
    .close-icon
        position: absolute
        top: 24px
        right: 24px
        width: 50px
        height: 50px
        padding: 10px
        margin-bottom: 20px
        cursor: pointer
        margin: 0 auto
    .mobile-menu
        width: 100%
        .header-nav
            margin-right: 0px
            @include media("tablet")
                display: flex
                flex-direction: column
                align-items: center
                justify-content: center
                .nav-link
                    font-size: 22px
                    margin-right: 0px
                    text-align: center
                    margin-bottom: 16px
    .header-contact
        margin: 32px auto 0
        width: 300px
        justify-content: center
        display: flex
        padding: 12px 0

.footer-nav
    display: flex
    align-items: center
    justify-content: flex-end
    flex-grow: 1
    margin-right: 50px
    .nav-link
        color: white
        text-decoration: none
        margin-right: 30px
        font-weight: 600
        font-size: 14px
        padding: 4px 6px
        opacity: .6
        &:last-of-type
            margin-right: 0px
        &:hover
            opacity: .8
        &.active
            opacity: 1

.not-found-page
    background-color: black
    background-image: linear-gradient(to top, transparentize($brown-bg, .1), transparentize($brown-bg, .5)), url("../../public/img/jumbo.jpeg")
    background-size: cover
    background-position: center
    height: 100vh
    .content-wrapper
        justify-content: center
        align-items: center
        display: flex
        height: 100vh
        .not-found-title
            color: white
            font-size: 32px

.jumbo-wrapper
    padding: 200px 0 150px
    background-color: black
    background-image: linear-gradient(to top, transparentize($brown-bg, .1), transparentize($brown-bg, .5)), url("../../public/img/jumbo.jpeg")
    background-size: cover
    background-position: center
    @include media("tablet")
        padding-top: 150px
        padding-bottom: 200px
    .request-call-wrapper
        position: relative
        .request-call
            background-color: white
            padding: 34px
            height: 120px
            border-radius: 30px
            box-shadow: 0px 10px 30px -10px transparentize($brown-bg, .7), 0px 8px 10px -10px transparentize($brown-bg, .9),
            position: absolute
            bottom: -210px
            left: 20px
            right: 20px
            display: flex
            align-items: center
            justify-content: space-between
            .main-btn
                height: 100%
            .request-call-input
                height: 100%
                flex-grow: 1
                margin-right: 30px
                padding: 0 12px
                border: 1px solid transparentize($brown-bg, .5)
                border-radius: 6px
            @include media("tablet")
                bottom: -310px
                flex-direction: column
                border-radius: 20px
                height: 220px
                padding: 24px
                .request-call-input
                    height: 52px
                    width: 100%
                    margin-right: 0
                    margin-bottom: 20px
                .main-btn
                    height: 52px
                    width: 100%

    .jumbo
        .heading
            color: white
            font-size: 48px
            font-weight: 600
            line-height: 1.2
            width: 60%
            margin-block-end: 20px
        .subheading
            color: white
            font-size: 24px
            font-weight: 500
            line-height: 1.4
            width: 50%
        @include media("tablet")
            .heading
                font-size: 28px
                width: 100%
                text-align: center
            .subheading
                font-size: 18px
                width: 80%
                margin: 0 auto
                text-align: center
    &.catalog-jumbo
        padding: 150px 0 150px
        background-image: linear-gradient(to top, transparentize($brown-bg, .1), transparentize($brown-bg, .4)), url("../../public/img/catalog-jumbo.png")
        .heading
            text-align: center
            width: 80%
            margin: 0 auto 50px
        .type-line-selector
            width: 70%
            margin: 0 auto
        @include media("desktop")
            .heading
                font-size: 28px
                width: 100%
        @include media("tablet")
            .type-line-selector
                width: 90%
                font-size: 14px

    &.blog-jumbo
        padding: 140px 0 150px
        background-image: linear-gradient(to top, transparentize($brown-bg, .1), transparentize($brown-bg, .4)), url("../../public/img/blog-jumbo.png")
        .heading
            text-align: center
            width: 80%
            margin: 0 auto 50px
        .subheading
            font-size: 22px
            text-align: center
            width: 70%
            margin: 0 auto
        @include media("desktop")
            .heading
                font-size: 28px
                width: 100%
            .subheading
                width: 100%
                font-size: 18px
    &.post-jumbo
        padding: 140px 0 50px
        background-image: linear-gradient(to top, transparentize($brown-bg, .1), transparentize($brown-bg, .4)), url("../../public/img/blog-jumbo.png")
        .heading
            text-align: center
            width: 80%
            margin: 0 auto 50px
        .subheading
            text-align: center
            width: 70%
            margin: 0 auto
    &.estate-jumbo
        padding: 160px 0 0
        height: 300px
        .heading
            text-align: center
            margin: 0 auto 50px
    &.contact-jumbo
        background-image: linear-gradient(to top, transparentize($brown-bg, .1), transparentize($brown-bg, .4)), url("../../public/img/contact-jumbo.png")
        height: 300px
        padding: 160px 0 0
        .heading
            text-align: center
            margin: 0 auto 50px

    &.about-jumbo
        background-image: linear-gradient(to top, transparentize($brown-bg, .1), transparentize($brown-bg, .4)), url("../../public/img/about-jumbo.png")
        height: auto
        padding: 160px 0 40px
        .jumbo
            .par
                color: white
                width: 60%
                font-size: 16px
                line-height: 1.5
                margin-bottom: 20px
                @include media("tablet")
                    width: 100%
        .heading
            text-align: center
            margin: 0 auto 50px

.estate-slide
    width: 100%
    color: $brown-bg
    padding-bottom: 14px
    .estate-photo
        display: block
        height: 240px
        background-position: center
        background-size: cover
        background-repeat: no-repeat
        border-radius: 5px
        background-color: #D9D9D9
    .estate-title
        font-size: 16px
        font-weight: 600
        margin: 12px 0 4px
        display: flex
        align-items: center
        justify-content: space-between
        .star
            path
                fill: #f0932b
    .estate-row
        display: flex
        align-items: center
        justify-content: space-between
        &.last-row
            margin-top: 16px
    .estate-floors
        font-size: 14px
        color: transparentize($brown-bg, .5)
        &::before
            content: "Этажей: "
    .estate-bedrooms
        font-size: 14px
        color: transparentize($brown-bg, .5)
        &::before
            content: "Спален: "
    .estate-square
        font-size: 14px
        color: transparentize($brown-bg, .5)
        &::before
            content: "Метраж: "
        &::after
            content: "м²"
    .estate-location
        font-size: 14px
        color: transparentize($brown-bg, .5)
        margin-block-end: 8px
        display: flex
        align-items: center
        &::before
            content: ''
            display: block
            width: 14px
            height: 14px
            opacity: .5
            background-image: url('../../public/img/location-dot.svg')
            background-repeat: no-repeat
            background-position: center
            background-size: contain
            margin-right: 6px
    .estate-price
        font-weight: 600
        font-size: 18px

.estate-slider-wrapper
    margin-block-end: 30px 0 20px
    .slider-preloader
        display: flex
        align-items: center
        justify-content: center
        height: 120px
    .slider-title
        padding: 40px 0
        font-weight: 500
        font-size: 32px
        color: $brown-bg
        @include media("tablet")
            font-size: 24px
            text-align: center
    .slider-controls
        width: 100%
        display: flex
        align-items: center
        justify-content: center
        height: 70px
        position: absolute
        z-index: 10
        bottom: 0
        .slider-slide-btn
            height: 100%
            margin: 0 80px
            width: 32px
            height: 32px
            flex-shrink: 0
            background: none
            border: none
            outline: none
            cursor: pointer
            background-color: #b2b1b1
            border-radius: 100px
            display: flex
            align-items: center
            justify-content: center
            transition: opacity .5s ease
            padding: 10px

            
            &:disabled
                opacity: .5
                cursor: default
            

    .swiper-pagination
        position: static
        display: flex
        align-items: center
        justify-content: center
        bottom: 0px
        height: 70px
        .swiper-pagination-bullet
            width: 10px
            height: 10px
            margin: 0 8px
            background-color: #D9D9D9
            opacity: 1
        .swiper-pagination-bullet-active
            background-color: $brown-bg
    

.sliders-wrapper
    padding-top: 80px
    @include media("tablet")
        padding-top: 160px

.about-section
    padding: 100px 0
    margin-top: 50px
    background-image: linear-gradient(to top, transparentize($brown-bg, 0), transparentize($brown-bg, .5)), url('../../public/img/about-section.png')
    background-size: cover
    background-repeat: no-repeat
    background-position: center
    .heading
        font-size: 32px
        font-weight: 700
        margin-bottom: 30px
        color: white
    .par
        color: white
        font-size: 16px
        line-height: 1.6
        width: 60%
        margin-block-end: 30px
    @include media("tablet")
        .par
            width: 100%
            font-size: 16px


.estates-grid-wrapper
    padding-top: 40px
    padding-bottom: 100px
    .estates-grid
        display: grid
        grid-template-columns: repeat(4, 1fr)
        row-gap: 40px
        column-gap: 40px
    @include media("desktop")
        .estates-grid
            grid-template-columns: repeat(3, 1fr)
            row-gap: 30px
            column-gap: 30px
    @include media("tablet")
        .estates-grid
            grid-template-columns: repeat(2, 1fr)
            row-gap: 20px
            column-gap: 20px
    @include media("mobile")
        .estates-grid
            grid-template-columns: repeat(1, 1fr)


.catalog-title
    padding: 120px 0 0
    font-weight: 500
    font-size: 32px
    color: $brown-bg
    @include media("tablet")
        padding-top: 240px

.line-selector-wrapper
    display: flex
    align-items: center
    justify-content: space-between
    position: relative
    .line-selector-line-wrapper
        position: absolute
        bottom: 0
        left: 0
        width: 100%
        border-radius: 2px
        height: 2px
        background-color: rgba(255,255,255,.3)
        .line-selector-line
            width: 100%
            height: 100%
            border-radius: 3px
            background: white
            will-change: transform
            transition: transform .3s ease
    .line-selector-option
        cursor: pointer
        padding: 12px 0
        width: 100%
        opacity: .5
        user-select: none
        display: flex
        align-items: center
        justify-content: center
        color: white
        text-align: center
        will-change: opacity
        transition: opacity .3s ease
        font-weight: 500
        &:hover
            opacity: .7
        &.active
            opacity: 1

.buttons-selector-wrapper
    display: flex
    align-items: center
    justify-content: space-between
    position: relative
    .buttons-selector-option
        background-color: white
        font-weight: 500
        cursor: pointer
        border: 1px solid transparentize($brown-bg, .7)
        border-radius: 5px
        width: 32px
        height: 32px
        text-align: center
        display: flex
        align-items: center
        justify-content: center
        color: $brown-bg
        margin-right: 10px
        transition: background-color .3s ease, color .3s ease, border-color .3s ease
        &:hover
            border: 1px solid transparentize($brown-bg, 0)
        &.active
            color: white
            background-color: $brown-bg


.estate-filter-wrapper
    position: relative
    .estate-filter
        background-color: white
        padding: 34px
        height: 120px
        border-radius: 30px
        box-shadow: 0px 10px 30px -10px transparentize($brown-bg, .7), 0px 8px 10px -10px transparentize($brown-bg, .9),
        position: absolute
        bottom: -210px
        left: 20px
        right: 20px
        display: flex
        align-items: center
        justify-content: space-between
    @include media("tablet")
        .estate-filter
            border-radius: 20px
            // padding: 24px
            height: 280px
            left: 20px
            right: 20px
            bottom: -340px
            flex-direction: column
            align-items: flex-start
            .label
                font-size: 14px
                margin-right: 20px
    @include media("mobile")
        .estate-filter
            .label
                width: 80px
        

.bedrooms-selector, .floors-selector, .square-selector, .price-selector
    display: flex
    align-items: center
    .label
        margin-right: 12px
        font-size: 16px
        font-weight: 500

.dropdown-selector-wrapper
    position: relative
    .dropdown-selector
        padding-left: 18px
        padding-right: 32px
        appearance: unset
        background: white
        height: 32px
        border: 1px solid transparentize($brown-bg, .7)
        border-radius: 5px
        line-height: 32px
        color: $brown-bg
    &::after
        position: absolute
        opacity: .7
        right: 12px
        top: 11px
        content: ""
        border: solid black
        border-width: 0 2px 2px 0
        display: inline-block
        padding: 2px
        transform: rotate(45deg)

.grid-preloader
    padding-top: 40px
    display: flex
    align-items: center
    justify-content: center
    min-height: 500px

.estates-grid-empty-label
    display: flex
    align-items: center
    justify-content: center
    font-weight: 500
    font-size: 18px
    min-height: 300px


.site-footer-wrapper
    background-color: $brown-bg
    padding: 0 20px
    box-shadow: 0 3px 0 $brown-bg
    .site-footer
        padding: 20px 0 0
        display: flex
        align-items: center
        justify-content: space-between
        .footer-contacts
            display: flex
        .footer-nav
            justify-content: flex-start
            padding: 0 30px
        .footer-logo
            height: 74px
            margin-right: 20px
        .messanger-contact-wrapper
            margin-left: 30px
            display: flex
            align-items: center
            justify-self: flex-end
            .contact-label
                font-size: 14px
                font-weight: 500
                color: white
                margin-left: 10px
    @include media("desktop")
        padding: 0 20px
        .footer-nav
            display: none

    @include media("mobile")
        .site-footer
            padding-top: 30px
            flex-direction: column
            width: 100%
            .footer-logo
                margin-right: 0
            .footer-contacts
                width: 100%
                margin-top: 30px
            .messanger-contact-wrapper
                width: 100%
                




.blog-posts-empty
    min-height: calc(100vh - 500px)
    display: flex
    align-items: center
    justify-content: center
    .blog-posts-empty-label
        font-size: 18px
        font-weight: 600
        color: $brown-bg

.subscribe-block-wrapper
    position: relative
    .subscribe-block
        background-color: white
        padding: 34px
        height: 120px
        border-radius: 30px
        box-shadow: 0px 10px 30px -10px transparentize($brown-bg, .7), 0px 8px 10px -10px transparentize($brown-bg, .9),
        position: absolute
        bottom: -210px
        left: 0px
        right: 0px
        display: flex
        align-items: center
        justify-content: space-between
        .main-btn
            height: 100%
        .subscribe-block-input
            height: 100%
            flex-grow: 1
            margin-right: 30px
            padding: 0 12px
            border: 1px solid transparentize($brown-bg, .5)
            border-radius: 6px
    @include media("tablet")
        .subscribe-block
            flex-direction: column
            padding: 24px
            height: 160px
            border-radius: 20px
            .subscribe-block-input
                width: 100%
                margin-right: 0
                height: 52px
                margin-bottom: 16px
            .main-btn
                width: 100%
                height: 52px

.blog-posts-wrapper
    padding: 150px 0 100px

.blog-post-item
    display: flex
    align-items: stretch
    justify-content: space-between
    margin-bottom: 60px
    min-height: 380px
    padding: 20px 0
    .cover
        width: 48%
        border-radius: 10px
        background-color: #D9D9D9
        background-repeat: no-repeat
        background-size: cover
        background-position: center
    .post
        width: 48%
        margin: 0
        display: flex
        flex-direction: column
        justify-content: space-between
        align-items: flex-start
        padding: 20px 0
        .post-created
            font-size: 14px
            margin-bottom: 12px
            color: rgba(0,0,0,.5)
        .post-title
            font-size: 22px
            line-height: 100%
            font-weight: 600
            margin: 0 0 16px
        .post-content
            font-size: 14px
            line-height: 1.6
            margin-bottom: 16px
            flex-grow: 1
    &:nth-of-type(even)
        flex-direction: row-reverse
    @include media("tablet")
        flex-direction: column!important
        margin-bottom: 30px
        .cover
            width: 100%
            height: 240px
        .post
            width: 100%
        
        

.post-preloader, .blog-preloader
    padding-top: 40px
    display: flex
    align-items: center
    justify-content: center
    min-height: 600px
    width: 100%
    @include media("tablet")
        min-height: 400px

.estate-preloader
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    min-height: 600px


.post-wrapper
    padding: 40px 0 60px
    display: flex
    justify-content: space-between
    .post-cover
        height: 320px
        width: 48%
        border-radius: 10px
        background-color: #D9D9D9
        background-repeat: no-repeat
        background-size: cover
        background-position: center
        margin-bottom: 80px
    .post
        width: 48%
        .post-created
            font-size: 14px
            margin-bottom: 12px
            color: rgba(0,0,0,.5)
        .post-title
            font-size: 22px
            line-height: 100%
            font-weight: 600
            margin: 0 0 16px
        .post-content
            font-size: 14px
            line-height: 1.6
            margin-bottom: 24px
            flex-grow: 1
    @include media("mobile")
        padding: 10px 0 0px
        flex-direction: column-reverse
        top: -20px
        .post-cover
            width: 100%
            height: 240px
        .post
            width: 100%
            margin-bottom: 40px

.estate-content-wrapper
    padding: 30px 0 50px

.estate-content
    .swiper-pagination-bullet
        filter: drop-shadow(0 2px 4px rgba(0,0,0,.2))
    .swiper-pagination-bullet-active
        background: white
    .line-selector-wrapper
        margin-bottom: 20px
        .line-selector-option
            color: $brown-bg
        .line-selector-line-wrapper
            background-color: transparentize($brown-bg, .9)
            .line-selector-line
                background-color: $brown-bg

    
.estate-gallery
    width: 100%
    position: relative
    margin-top: 30px
    .gallery-controls
        .slider-slide-btn
            position: absolute
            width: 60px
            height: 100px
            border: none
            background: none
            top: 50%
            transform: translateY(-50%)
            z-index: 1000
            filter: drop-shadow(0 2px 4px rgba(0,0,0,.2))
            cursor: pointer
            display: flex
            justify-content: center
            align-items: center
            opacity: .8
            transition: .3s opacity ease
            img
                width: 32px
                height: 32px
            &.prev
                left: 0px
            &.next
                right: 0px
            &:hover
                opacity: 1
            &:disabled
                opacity: .5
                cursor: default
    .photo-slider-wrapper
        background: rgba(0,0,0,.03)
        border-radius: 10px
        overflow: hidden
        .photo-slide
            width: 100%
            padding-top: 50%
            position: relative
            background-color: #D9D9D9
            background-repeat: no-repeat
            background-size: cover
            background-position: center
    .estate-gallery-empty
        width: 100%
        height: 400px
        position: relative
        background: rgba(0,0,0,.03)
        border: 1px solid transparentize($brown-bg, .92)
        border-radius: 10px
        display: flex
        align-items: center
        justify-content: center
        .estate-gallery-empty-label
            font-size: 18px
            font-weight: 500

.estate-content
    padding-bottom: 100px
    .left-side
        display: flex
        flex-direction: column
        justify-content: center
    .estate-location
        font-size: 14px
        margin-top: 8px
        color: transparentize($brown-bg, .5)
        display: flex
        align-items: center
        &::before
            content: ''
            display: block
            width: 14px
            height: 14px
            opacity: .5
            background-image: url('../../public/img/location-dot.svg')
            background-repeat: no-repeat
            background-position: center
            background-size: contain
            margin-right: 6px
    .estate-price-wrapper
        display: flex
        align-items: center
        justify-content: space-between
        margin-bottom: 40px
        .per-square
            flex-grow: 1
            font-size: 16px
            line-height: 16px
            opacity: .7
        .estate-price
            font-weight: 600
            font-size: 24px
            line-height: 24px
            margin-right: 14px
    .estate-info-wrapper
        position: relative
        width: 100%
        display: flex
        justify-content: space-between
        align-items: stretch
        .estate-info
            position: relative
            top: 1px
            background: rgba(0,0,0,.03)
            border: 1px solid transparentize($brown-bg, .92)
            padding: 20px 40px
            flex-shrink: 0
            border-radius: 10px
            display: flex
            width: 40%
            justify-content: space-between
            align-items: center
            flex-wrap: wrap
            .info-item
                width: 46%
                padding: 10px 0
                .info-label
                    font-weight: 500
                    font-size: 14px
                    opacity: .5
                    margin-bottom: 2px
                .info-value
                    color: $brown-bg
                    font-size: 16px
                    font-weight: 500
    @include media("tablet")
        .estate-price-wrapper
            flex-direction: column
            align-items: flex-start
            .left-side
                width: 100%
                margin-bottom: 40px
        .estate-info-wrapper
            flex-direction: column
            .estate-info
                width: 100%
                margin-bottom: 40px
                padding: 13px 20px
                .info-item
                    padding: 14px 0
            .estate-gallery
                width: 100%


.contact-page-wrapper
    padding: 80px 0
    .contact-page
        display: flex
        align-items: stretch
        justify-content: space-between
        padding: 0 20px
        .contact-about
            display: flex
            flex-direction: column
            justify-content: center
            width: 46%
            .title
                margin-bottom: 24px
                font-size: 28px
                font-weight: 600
            .par
                margin-bottom: 14px
                line-height: 1.5
                font-size: 16px
        .contact-form-wrapper
            width: 46%
    @include media("tablet")
        padding: 30px 0
        .contact-page
            flex-direction: column
            .contact-about, .contact-form-wrapper
                width: 100%
            .contact-about
                margin-bottom: 40px


.contact-form
    height: 100%
    display: flex
    flex-direction: column
    padding: 40px
    border-radius: 10px
    background-color: white
    box-shadow: 0 8px 36px -12px transparentize($brown-bg, .7), 0 6px 16px -5px transparentize($brown-bg, .9)
    .c1-input
        border: 1px solid $brown-bg
        padding: 6px 12px
        border: 1px solid transparentize($brown-bg, .7)
        border-radius: 6px
        color: $brown-bg
        font-size: 14px
        height: 44px
        margin-bottom: 14px
        transition: border-color .3s ease
        &:focus
            border-color: $brown-bg
    .textarea
        height: 200px
        margin-bottom: 28px
        resize: none
    .main-btn
        height: 44px
        transition-property: background-color, opacity
        transition-timing-function: ease
        transition-duration: .3s
    @include media("tablet")
        padding: 30px

.contact-map-wrapper
    background-image: linear-gradient(to top, transparentize(white, .5), white), url("../../public/img/map.png")
    padding-bottom: 300px
    margin-top: 60px
    background-size: cover
    background-position: bottom
    @include media("tablet")
        background-image: linear-gradient(to top, transparentize(white, .2), white), url("../../public/img/map.png")
        padding-top: 40px
        padding-bottom: 100px

.contact-cards
    display: flex
    justify-content: space-between
    align-items: center
    @include media("tablet")
        flex-direction: column
        align-items: flex-start

.contact-card
    display: flex
    align-items: center
    padding: 20px
    .icon
        width: 64px
        height: 64px
        background-size: 25px
        background-position: center
        background-repeat: no-repeat
        background-color: transparentize($brown-bg, .9)
        border-radius: 25px
    .card-content
        margin-left: 20px
        display: flex
        flex-direction: column
        align-items: flex-start
        justify-content: flex-start

.about-page-wrapper
    padding: 80px 20px 40px
    .flow
        display: none // fix or smt
    .about-cards-title
        margin-bottom: 24px
        width: 50%
        font-size: 22px
        font-weight: 500
        @include media("tablet")
            width: 100%
            text-align: center
    .team-cards-title
        margin-bottom: 14px
        width: 50%
        font-size: 22px
        font-weight: 500
        @include media("tablet")
            width: 100%
            text-align: center
    .par
        font-size: 16px
        line-height: 1.5
        width: 60%
        margin-bottom: 20px
        color: $brown-bg
        &:last-of-type
            margin-bottom: 64px
        @include media("tablet")
            width: 100%

.about-cards
    padding: 30px 0
    display: flex
    flex-wrap: wrap
    align-items: stretch
    justify-content: space-between
    margin-bottom: 80px
    .about-card
        width: 30%
        display: flex
        flex-direction: column
        margin-bottom: 40px
        .icon
            width: 64px
            height: 64px
            background-size: auto 25px
            background-position: center
            background-repeat: no-repeat
            background-color: transparentize($brown-bg, .9)
            border-radius: 25px
            margin-bottom: 16px
        .content
            font-size: 16px
            color: $brown-bg
            line-height: 1.5
    @include media("tablet")
        .about-card
            width: 48%
    @include media("mobile")
        .about-card
            width: 100%

.team-cards
    padding: 30px 0 20px
    display: flex
    align-items: stretch
    justify-content: space-between
    flex-wrap: wrap
    .team-card
        width: 24%
        display: flex
        flex-direction: column
        align-items: center
        margin-bottom: 50px
        .card-photo
            width: 150px
            height: 150px
            border-radius: 150px
            background-repeat: no-repeat
            background-color: #D9D9D9
            background-position: center
            background-size: cover
            margin-bottom: 10px
        .card-name
            font-weight: 600
            font-size: 16px
            text-align: center
        .card-position
            font-weight: 400
            font-size: 14px
            text-align: center
    @include media("tablet")
        .team-card
            width: 48%
    @include media("mobile")
        .team-card
            width: 100%

.contact-form-modal
    display: flex
    flex-direction: column
    padding: 40px
    width: 100%
    @include media("mobile")
        padding: 32px
.contact-form-input
    border: 1px solid $brown-bg
    padding: 6px 12px
    border: 1px solid transparentize($brown-bg, .7)
    border-radius: 6px
    color: $brown-bg
    font-size: 14px
    height: 44px
    margin-bottom: 14px
    transition: border-color .3s ease
    &:focus
        border-color: $brown-bg
.textarea
    height: 200px
    margin-bottom: 28px
    resize: none
.main-btn
    height: 44px
    transition-property: background-color, opacity
    transition-timing-function: ease
    transition-duration: .3s

.show-more-wrapper
    display: flex
    align-items: center
    justify-content: center
    padding: 80px 0 20px

.estate-desc-wrapper
    margin-top: 30px
    display: flex
    align-items: stretch
    justify-content: space-between
    .block-contact-form
        background: rgba(0,0,0,.03)
        border: 1px solid transparentize($brown-bg, .92)
        padding: 40px
        border-radius: 10px
        box-shadow: none
        flex-grow: 1
        display: flex
        flex-direction: column
        .textarea
            height: 100px
        .contact-header
            flex-grow: 1
            margin-bottom: 24px
            .title
                line-height: 1.3
                margin-bottom: 6px
            .text
                font-size: 14px
    @include media("tablet")
        flex-direction: column
        .block-contact-form .contact-header .text
            margin-bottom: 16px


.estate-map-wrapper
    width: 65%
    flex-shrink: 0
    background: rgba(0,0,0,.03)
    border: 1px solid transparentize($brown-bg, .92)
    margin-right: 30px
    overflow: hidden
    border-radius: 10px
    .estate-map
        width: 100%
        height: 100%
    @include media("tablet")
        height: 400px
        width: 100%
        margin-bottom: 40px

    
.estate-about-text
    font-size: 14px
    line-height: 1.6
    opacity: .8
    width: 50%
    .about-title
        font-size: 18px
        font-weight: 600
        margin-bottom: 6px
    @include media("tablet")
        width: 100%
        margin-bottom: 40px

.login-form-wrapper
  width: 100vw
  height: 100vh
  display: flex
  align-items: center
  justify-content: center

.login-form
  width: 400px
  height: 300px

.admin-header-wrapper
    background-color: $brown-bg
    .admin-header
        display: flex
        align-items: center
        justify-content: space-between
        width: $site-width
        margin: 0 auto
        padding: 14px 20px
        color: white
        .title
            font-weight: 600
        .admin-header-nav
            a
                padding: 6px 12px
                text-decoration: none
                font-size: 14px
                font-weight: 500
                margin-left: 30px
                transition: color .3s ease
                color: rgba(255,255,255,.5)
                &:hover
                    color: rgba(255,255,255,.7)
                &.active
                    color: rgba(255,255,255,1)

.admin-page
    max-width: $site-width
    padding: 16px 20px 40px
    width: 100%
    min-height: calc(100vh - 52px)
    margin: 0 auto

.admin-page-wrapper
    background-color: rgb(240,243,246)

.page-actions
    display: flex
    justify-content: space-between
    align-items: center
    padding: 22px 0 12px
    .page-title
        font-size: 18px
        font-weight: 600

.estate-info
    .form-row-item
        width: 19%

.estate-location
    .form-row-item-59
        width: 59%
    .form-row-item-19
        width: 19%

.form-row
    display: flex
    align-items: center
    justify-content: space-between
    @include media("tablet")
        flex-wrap: wrap
        .form-row-item
            width: 100%

.preloader-wrapper
    height: 500px
    width: 100%
    display: flex
    align-items: center
    justify-content: center

.contact-form-error
    border-left: 2px solid #ee5253
    font-size: 14px
    padding: 6px 0
    padding-left: 10px
    margin-bottom: 24px
    color: transparentize(#ee5253, .2)

.mobile-menu-toggle
    display: none
    flex-direction: column
    justify-content: space-evenly
    align-items: center
    width: 54px
    height: 54px
    padding: 8px
    cursor: pointer
    .line
        height: 2px
        width: 32px
        border-radius: 5px
        background: white
    @include media("tablet")
        display: flex

.tg-icon
    width: 32px
    height: 32px
    margin-left: 20px
    background-image: url("../../public/img/telegram.svg")
    background-size: cover
    background-repeat: no-repeat
    background-position: center 

.cta-popunder
    position: fixed
    z-index: 150
    bottom: 20px
    right: 20px
    background: #1C1814
    box-shadow: 0 5px 7px -4px rgba(0,0,0,.3)
    padding: 24px
    padding-right: 18px
    border-radius: 15px
    display: flex
    align-items: center
    justify-content: space-between
    flex-direction: row-reverse
    transform: translateY(200px)
    transition: transform .3s ease
    .close
        margin-left: 12px
        background: none
        border: none
        width: 20px
        height: 20px
        display: flex
        align-items: center
        justify-content: center
        color: white
        font-size: 24px
        cursor: pointer
    .text
        color: white
        font-size: 14px
        font-weight: 500
    &.show
        transform: translateY(0)
    @include media("mobile")
        padding-right: 10px
        left: 8px
        right: 8px
        bottom: 0
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0

.privacy-wrapper
    padding: 20px 15px
    h2
        color: $brown-bg
        font-size: 22px
        margin-bottom: 14px
    h3
        color: $brown-bg
        font-size: 16px
        margin-bottom: 14px
    p
        font-size: 14px
        line-height: 1.5
        color: transparentize($brown-bg, .3)
        margin-bottom: 12px

.privacy-link-wrapper
    display: flex
    align-items: center
    justify-content: center
    padding: 22px 0 8px
    padding-bottom: 20px
    .privacy-link
        color: rgba(255,255,255,.5)
        text-decoration: none
        font-size: 14px